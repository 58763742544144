import styled from "styled-components";

export const Row = styled("tr")<{ "data-group-type"?: string }>((props) => ({
  [`&[data-group-state=false] ~ [data-group-type='${props["data-group-type"]}']`]:
    {
      display: "none",
    },
}));

export const Wrapper = styled("div")({
  overflowX: "auto",
});

export const Techniques = styled("div")({
  display: "grid",
  gridAutoColumns: 250,
  gridAutoFlow: "dense",
  gap: 20,
  gridTemplateColumns: "repeat(6, 1fr)",
  gridTemplateRows: "masonry",
  "@media (max-width: 1280px)": {
    gridTemplateColumns: "repeat(5, 1fr)",
  },
  "@media (max-width: 1024px)": {
    gridTemplateColumns: "repeat(4, 1fr)",
  },
  "@media (max-width: 960px)": {
    gridTemplateColumns: "repeat(3, 1fr)",
  },
  "@media (max-width: 840px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
});

const statusColor = {
  '&[data-status="2"]': {
    // backgroundColor: "rgba(0, 168, 142, 0.3)",
    backgroundColor: "#29CCB1",
  },
  '&[data-status="1"]': {
    // backgroundColor: "rgba(0, 0, 0, 0.05)",
    backgroundColor: "#d9dde1",
  },
};

export const Legends = styled("div")({
  marginBottom: 20,
  display: "flex",
  flexWrap: "wrap",
  gap: 20,
});

export const Legend = styled("div")({
  "&:before": {
    content: "''",
    background: "#000",
    borderRadius: 10,
    ...statusColor,
    display: "inline-block",
    width: 10,
    height: 10,
  },
  ...Object.entries(statusColor).reduce(
    (dict, [key, value]) => ({
      ...dict,
      [`${key}:before`]: value,
    }),
    {}
  ),
  display: "flex",
  alignItems: "baseline",
  gap: 10,
});

export const Technique = styled("div")<{
  $expanded?: boolean;
  $count?: number;
}>(({ $expanded, $count }) => ({
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: "rgba(0,0,0,.1)",
  ...(typeof $count === "number" && $count > 0 ? { cursor: "pointer" } : {}),
  [Subtechniques]: {
    display: $expanded ? "block" : "none",
  },
  ...statusColor,
}));

export const Title = styled("div")({});
export const Label = styled("label")({
  fontSize: "smaller",
});

export const Item = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 12,
});

export const Subtechniques = styled("div")({
  fontSize: "small",
  display: "none",
  borderTop: "1px solid rgba(0,0,0,.1)",
  background: "#fff",
});

export const Subtechnique = styled("div")({
  "&:not(:first-child)": {
    borderTop: "1px solid rgba(0, 0, 0, .05)",
  },
  [Item]: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  ...statusColor,
});
